<template>
  <div>
      <div class="view-wrapper">
            <h2 class="has-text-centered">User Details: {{ companyName }}</h2>
          <div class="page-content-wrapper is-size-default" style="padding-top: 20px; display: flex;">
              <div class="page-content is-relative is-half">
                  <form novalidate class="form-layout" @submit.prevent="submit">
                      <div class="form-outer">
                          <div class="form-header stuck-header">
                            <div class="form-header-inner">
                              <div class="left">
                                <h3>{{ isEditing ? 'Editing User Info' : 'User Info Details' }}</h3>
                              </div>
                              <div class="right">
                                <div class="buttons" v-if="admin_profile_status === USER_STATUS_LO_ADMIN">
                                  <button type="button" class="button v-button is-primary is-raised" @click="toggleEdit">
                                    <span>{{ isEditing ? 'Cancel' : 'Edit' }}</span>
                                  </button>
                                  <button v-if="isEditing" type="button" class="button v-button is-primary is-raised" @click="saveProfile">
                                    <span>Save</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-body">
                                <div class="form-fieldset">
                                    <div class="columns is-multiline">
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">User Platform Unique ID </label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder v-model="userID" disabled="true"/>
                                                    <iconify-icon class="iconify form-icon" icon="tabler:id"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Registered Email </label>
                                                <div class="control has-icon">
                                                    <input type="email" class="input" placeholder autocomplete="email" inputmode="email" v-model="email" disabled="true"/>
                                                    <iconify-icon class="iconify form-icon" icon="lucide:mail"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Registration Time </label>
                                                <div class="control has-icon">
                                                    <input type="datetime" class="input" placeholder v-model="registrationTime" disabled="true"/>
                                                    <iconify-icon class="iconify form-icon" icon="lucide:mail"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Status </label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder v-model="status" :disabled="!isEditing"/>
                                                    <iconify-icon class="iconify form-icon" icon="f7:status"></iconify-icon>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Status </label>
                                                <div class="control">
                                                    <div class="multiselect" :class="{'is-disabled': !isEditing}">
                                                        <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="isEditing &&toggleDropdown('status')">
                                                            <div class="multiselect-placeholder" v-if="status === ''">Select a size</div>
                                                            <div class="multiselect-single-label" v-else>
                                                                <div class="multiselect-single-label-text">{{ status }}</div>
                                                            </div>
                                                            <div class="multiselect-caret">
                                                                <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                            </div>
                                                        </div>
                                                        <div class="multiselect-dropdown is-hidden" tabindex="1" id="status">
                                                            <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                                <li class="multiselect-option" role="option" @click="status='New'" :class="{ 'is-selected': status === 'New' }">
                                                                    <span>New</span>
                                                                </li>
                                                                <li class="multiselect-option" role="option" @click="status='In waiting list'" :class="{ 'is-selected': status === 'In waiting list' }">
                                                                    <span>In waiting list</span>
                                                                </li>
                                                                <li class="multiselect-option" role="option" @click="status='Active'" :class="{ 'is-selected': status === 'Active' }">
                                                                    <span>Active</span>
                                                                </li>
                                                                <li class="multiselect-option" role="option" @click="status='QL Admin'" :class="{ 'is-selected': status === 'QL Admin' }">
                                                                    <span>QL Admin</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Current Credits </label>
                                                <div class="control has-icon">
                                                    <input type="number" class="input" placeholder v-model="credits" :disabled="!isEditing"/>
                                                    <iconify-icon class="iconify form-icon" icon="entypo:credit"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="form-fieldset">
                                    <div class="columns is-multiline">
                                        <div class="fieldset-heading">
                                            <h4>User Infomation</h4>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">First Name </label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder autocomplete="given-name" v-model="firstname" 
                                                    :disabled="!isEditing"/>
                                                    <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Last Name </label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder autocomplete="family-name" v-model="lastname" :disabled="!isEditing"/>
                                                    <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Job Level </label>
                                                <div class="multiselect" :class="{'is-disabled': !isEditing}">
                                                    <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="isEditing && toggleDropdown('jobLevel')" >
                                                    <div class="multiselect-placeholder" v-if="jobLevel === ''">Job Level</div>
                                                    <div class="multiselect-single-label" v-else>
                                                        <div class="multiselect-single-label-text">{{ jobLevel }}</div>
                                                    </div>
                                                    <div class="multiselect-caret">
                                                        <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                    </div>
                                                    </div>
                                                    <div class="multiselect-dropdown is-hidden" tabindex="1" id="jobLevel">
                                                        <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                            <div 
                                                            class="multiselect-option"
                                                            role="option"
                                                            v-for="job in jobLevels"    
                                                            :key="job"
                                                            @click.stop="handleSelectJobLevel(job)"
                                                            :class="{ 'is-selected': jobLevel === job }"
                                                            >
                                                            <span>{{ job }}</span>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="field">
                                            <label class="label">Job Function </label>
                                            <div class="multiselect" :class="{'is-disabled': !isEditing}">
                                                <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="isEditing && toggleDropdown('jobFunction')">
                                                <div class="multiselect-placeholder" v-if="jobFunction === ''">Job Function</div>
                                                <div class="multiselect-single-label" v-else>
                                                    <div class="multiselect-single-label-text">{{ jobFunction }}</div>
                                                </div>
                                                <div class="multiselect-caret">
                                                    <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                </div>
                                                </div>
                                                <div class="multiselect-dropdown is-hidden" tabindex="1" id="jobFunction">
                                                <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                    <div 
                                                    class="multiselect-option"
                                                    role="option"
                                                    v-for="func in jobFunctions"    
                                                    :key="func"
                                                    @click.stop="handleSelectJobFunction(func)"
                                                    :class="{ 'is-selected': jobFunction === func }"
                                                    >
                                                    <span>{{ func }}</span>
                                                    </div>
                                                </ul>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="field">
                                            <label class="label">Country/Region </label>
                                            <div class="multiselect" :class="{'is-disabled': !isEditing}">
                                                <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="isEditing && toggleDropdown('country')">
                                                <div class="multiselect-placeholder" v-if="country === ''">Country/Region</div>
                                                <div class="multiselect-single-label" v-else>
                                                    <div class="multiselect-single-label-text">{{ country }}</div>
                                                </div>
                                                <div class="multiselect-caret">
                                                    <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                </div>
                                                </div>
                                                <div class="multiselect-dropdown is-hidden" tabindex="1" id="country">
                                                <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                    <div 
                                                    class="multiselect-option"
                                                    role="option"
                                                    v-for="c in countries"    
                                                    :key="c"
                                                    @click.stop="handleSelectCountry(c)"
                                                    :class="{ 'is-selected': country === c }"
                                                    >
                                                    <span>{{ c }}</span>
                                                    </div>
                                                </ul>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Work Phone Number</label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder autocomplete="tel" v-model="phone" :disabled="!isEditing"/>
                                                    <iconify-icon class="iconify form-icon" icon="lucide:phone"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                  </div>
                              </div>
                              <div class="form-fieldset">
                                    <div class="fieldset-heading">
                                        <h4>Company Information</h4>
                                    </div>
                                    <div class="columns is-multiline">
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Company Name </label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder autocomplete="organization" v-model="companyName" :disabled="!isEditing"/>
                                                    <iconify-icon class="iconify form-icon" icon="lucide:building"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Company Size </label>
                                                <div class="control">
                                                    <div class="multiselect" :class="{'is-disabled': !isEditing}">
                                                        <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="isEditing &&toggleDropdown('companySize')">
                                                            <div class="multiselect-placeholder" v-if="companySize === ''">Select a size</div>
                                                            <div class="multiselect-single-label" v-else>
                                                                <div class="multiselect-single-label-text">{{ companySize }}</div>
                                                            </div>
                                                            <div class="multiselect-caret">
                                                                <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                            </div>
                                                        </div>
                                                        <div class="multiselect-dropdown is-hidden" tabindex="1" id="companySize">
                                                            <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                                <li class="multiselect-option" role="option" @click="handleSelectSize('1-10 Employees')" :class="{ 'is-selected': companySize === '1-10 Employees' }">
                                                                    <span>1-10 Employees</span>
                                                                </li>
                                                                <li class="multiselect-option" role="option" @click="handleSelectSize('11-50 Employees')" :class="{ 'is-selected': companySize === '11-50 Employees' }">
                                                                    <span>11-50 Employees</span>
                                                                </li>
                                                                <li class="multiselect-option" role="option" @click="handleSelectSize('51-100 Employees')" :class="{ 'is-selected': companySize === '51-100 Employees' }">
                                                                    <span>51-100 Employees</span>
                                                                </li>
                                                                <li class="multiselect-option" role="option" @click="handleSelectSize('100+ Employees')" :class="{ 'is-selected': companySize === '100+ Employees' }">
                                                                    <span>100+ Employees</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Company Type </label>
                                                <div class="control">
                                                    <div class="multiselect" :class="{'is-disabled': !isEditing}">
                                                        <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="isEditing &&toggleDropdown('companyType')">
                                                            <div class="multiselect-placeholder" v-if="companyType === ''">Select a type</div>
                                                            <div class="multiselect-single-label" v-else>
                                                                <div class="multiselect-single-label-text">{{ companyType }}</div>
                                                            </div>
                                                            <div class="multiselect-caret">
                                                                <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                            </div>
                                                        </div>
                                                        <div class="multiselect-dropdown is-hidden" tabindex="1" id="companyType">
                                                            <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                                <div 
                                                                    class="multiselect-option"
                                                                    role="option"
                                                                    v-for="labRole in accountRoles['lab']"    
                                                                    :key="labRole"
                                                                    @click.stop="handleSelectRole('lab', labRole)"
                                                                    :class="{ 'is-selected': companyType === labRole }"
                                                                >
                                                                    <span>{{ labRole }}</span>
                                                                </div>
                                                                <hr class="dropdown-divider">
                                                                <div 
                                                                    class="multiselect-option"
                                                                    role="option"
                                                                    v-for="userRole in accountRoles['user']"    
                                                                    :key="userRole"
                                                                    @click.stop="handleSelectRole('user', userRole)"
                                                                    :class="{ 'is-selected': companyType === userRole }"
                                                                >
                                                                    <span>{{ userRole }}</span>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Company Website</label>
                                                <div class="control has-icon">
                                                    <input type="url" class="input" placeholder autocomplete="url" v-model="companyWebsite" :disabled="!isEditing"/>
                                                    <iconify-icon class="iconify form-icon" icon="streamline:web"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          </div>
                      </div>
                  </form>
              </div>
              <div class="page-content is-relative is-half">
                <div class="datatable-container">
                    <div class="datatable">
                        <div class="notification-box">
                            <span class="notification-message">Data submission history</span>
                        </div>
                        <!-- <div class="datatable-toolbar">
                            <div class="field">
                            <div class="control has-icon">
                                <input class="input custom-text-filter" placeholder="Search..." v-model="searchQuery" />
                                <iconify-icon class="iconify form-icon" icon="lucide:search"></iconify-icon>
                            </div>
                            </div>
                            <div class="buttons">
                            <button type="button" class="button v-button is-elevated is-primary" @click="goTo('/data-submit/service-selection')">
                                <span class="icon rtl-reflect"><i class="fas fa-plus"></i></span>
                                <span>New Upload</span>
                            </button>
                            </div>
                        </div> -->

                        <div class="datatable-wrapper">
                            <div class="table-container">
                            <table class="table datatable-table is-fullwidth">
                                <thead>
                                <th>Name</th>
                                <th>Sample ID</th>
                                <th>Service</th>
                                <th>Type</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Reports</th>
                                </thead>
                                <tbody>
                                <tr v-if="paginatedUploads.length === 0">
                                    <td colspan="6" class="no-data-notification">No files uploaded</td>
                                </tr>
                                <tr v-else v-for="(upload, index) in paginatedUploads" :key="index">
                                    <td>
                                    <div class="text-wrap">{{ upload.name }}</div>
                                    </td>
                                    <td>{{ upload.sampleID }}</td>
                                    <td>{{ upload.category }}</td>
                                    <td>{{ upload.type }}</td>
                                    <td>{{ upload.date }}</td>
                                    <td>{{ upload.status }}</td>
                                    <td>
                                    <a :href="upload.reportLink" v-if="upload.status === 'Ready'">
                                        <button class="button v-action">Download</button>
                                    </a>
                                    <div class="status-text" v-else>In Preparation</div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>

                        <div class="v-flex flex-pagination pagination is-rounded mt-4" v-if="totalPages > 1">
                            <ul class="pagination-list">
                            <li v-for="page in visiblePages" :key="page">
                                <a
                                :class="{ 'is-current': page === currentPage }"
                                @click="changePage(page)"
                                class="pagination-link"
                                tabindex="0"
                                >
                                {{ page }}
                                </a>
                            </li>
                            </ul>
                            <a
                            :class="{ 'is-disabled': currentPage === 1 }"
                            @click="prevPage"
                            class="pagination-previous has-chevron"
                            tabindex="0"
                            >
                            <iconify-icon class="iconify rtl-hidden" icon="lucide:chevron-left"></iconify-icon>
                            <!-- <iconify-icon class="iconify ltr-hidden" icon="lucide:chevron-right"></iconify-icon> -->
                            </a>
                            <a
                            :class="{ 'is-disabled': currentPage === totalPages }"
                            @click="nextPage"
                            class="pagination-next has-chevron"
                            tabindex="0"
                            >
                            <!-- <iconify-icon class="iconify ltr-hidden" icon="lucide:chevron-left"></iconify-icon> -->
                            <iconify-icon class="iconify rtl-hidden" icon="lucide:chevron-right"></iconify-icon>
                            </a>
                        </div>
                </div>
            </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { jobLevels, jobFunctions, countries } from '../../services/jobInfo';
import { accountRoles } from '../../services/accountRoles';
import { STATUS_LIST, USER_STATUS_LO_ADMIN } from '../dashboard/dashboardConstants';
import { getUploadHistory } from "../data-submit/uploadHistory";


export default {
  name: 'VProfile',
  props: {
    user: Object,
    Required: true,
  },
  data() {
    return {
        admin_profile_status: '',
        USER_STATUS_LO_ADMIN: USER_STATUS_LO_ADMIN,
        jobLevels,
        jobFunctions,
        accountRoles,
        countries,
        itemsPerPage: 5,
        
        userID: '',
        registrationTime: '',
        status: '',
        credits: null,
        firstname: '',
        lastname: '',
        jobLevel: '',
        jobFunction: '',
        country: '',
        phone: '',
        email: '',
        companyName: '',
        companySize: '',
        companyType: '',
        companyWebsite: '',
        uploadHistory: [],
        totalPages: 0,
        currentPage: 1,
        paginatedUploads: [],
        isEditing: false,
    };
  },
  methods: {
    toggleDropdown(id) {
      const dropdown = document.getElementById(id);
      dropdown.classList.toggle('is-hidden');
    },

    handleSelectJobLevel(value) {
      this.jobLevel = value;
      this.toggleDropdown('jobLevel');
    },

    handleSelectJobFunction(value) {
      this.jobFunction = value;
      this.toggleDropdown('jobFunction');
    },

    handleSelectCountry(value) {
      this.country = value;
      this.toggleDropdown('country');
    },

    handleSelectSize(value) {
        if (value !== '1-10 Employees' && value !== '11-50 Employees' && value !== '51-100 Employees' && value !== '100+ Employees') {
            this.companySize = 'Invalid Size';
            return;
        }
        this.companySize = value;
        this.toggleDropdown('companySize');
    },

    handleSelectRole(type, value) {
        if (!this.accountRoles[type].includes(value)) {
            this.companyType = 'Invalid Role';
            return;
        }
        this.companyType = value;
        this.toggleDropdown('companyType');
    },

    handleClickOutside(event) {
      const dropdowns = document.querySelectorAll('.multiselect-dropdown');
      dropdowns.forEach((dropdown) => {
        if (!dropdown.contains(event.target)) {
          dropdown.classList.add('is-hidden');
        }
      });
    },

    async saveProfile() {
        const userRef = doc(db, 'users', this.userID);
        if (STATUS_LIST.indexOf(this.status) === -1) {
          alert('Invalid status. Please select a valid status: ' + STATUS_LIST.join(', '));
          return;
        }
        try {
          await setDoc(userRef, {
            status: this.status,
            credits: this.credits,
            firstname: this.firstname,
            lastname: this.lastname,
            jobLevel: this.jobLevel,
            jobFunction: this.jobFunction,
            country: this.country,
            phone: this.phone,
            companyName: this.companyName,
            companySize: this.companySize,
            companyType: this.companyType,
            companyWebsite: this.companyWebsite,
          }, { merge: true });
          this.isEditing = false;
          alert('Information successfully updated'); 
        } catch (error) {
          console.error('Error saving profile:', error);
          alert('An error occurred while saving the profile. Please try again.');
        }
    },

    toggleEdit() {
      this.isEditing = !this.isEditing;
    },

    async getUploads() {
      this.uploadHistory = await getUploadHistory(
        this.userID,
      );
      const start = (this.currentPage - 1) * this.itemsPerPage;

      this.totalPages = Math.ceil(
        this.uploadHistory.length / this.itemsPerPage
      );

      this.paginatedUploads = this.uploadHistory.slice(
        start,
        start + this.itemsPerPage
      );
    },
  },
  mounted() {
    this.userID = this.user.uid || 'ERROR UID';
    this.status = this.user.status || 'ERROR STATUS';
    this.registrationTime = this.user.createdAt
    this.credits = this.user.credits || 0;
    this.firstname = this.user.firstname || '';
    this.lastname = this.user.lastname || '';
    this.jobLevel = this.user.jobLevel || '';
    this.jobFunction = this.user.jobFunction || '';
    this.country = this.user.country || '';
    this.phone = this.user.phone || '';
    this.email = this.user.email || '';
    this.companyName = this.user.companyName || '';

    getDoc(doc(db, 'waitlist', this.userID)).then((doc) => {
      if (doc.exists()) {
        const data = doc.data();
        console.log(data.companySize);
        this.companySize = data.companySize || '';
        this.companyType = data.companyType || '';
        this.companyWebsite = data.companyWebsite || '';
      }
      else {
        this.companySize = this.user.companySize || '';
        this.companyType = this.user.companyType || '';
        this.companyWebsite = this.user.companyWebsite || '';
      }
    });
    this.getUploads();
    onAuthStateChanged(auth, (user) => {
        if (!user) {
            this.$router.push("/login");
        }

        const docRef = doc(db, "users", user.uid);
        getDoc(docRef).then((doc) => {
            if (doc.exists()) {
                this.admin_profile_status = doc.data().status;
                if (this.admin_profile_status !== USER_STATUS_QL_ADMIN && this.admin_profile_status !== USER_STATUS_LO_ADMIN) {
                    alert("You are not authorized to access this page");
                    this.$router.push("/dashboard");
                }
            } else {
                alert("Failed to get user profile");
                console.error("No such document for user profile", doc);
            }
        }).catch((error) => {
            console.error("Error getting document:", error);
        });
    });
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener('click', this.handleClickOutside);
    next();
  },
};
</script>

<style scoped lang="scss">
.input:disabled {
  border: none; /* Hide the border */
  background-color: #f0f0f0; /* Example background color */
  color: #a0a0a0; /* Example text color */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.multiselect {
  &.is-disabled {
    border: none;
    cursor: not-allowed;
    background-color: #f0f0f0;

    .multiselect-single-label-text {
      color: #a0a0a0;
    }

    * {
      cursor: not-allowed;
    }
  }
}

.view-wrapper {
  width: 100%;
  margin: 0;
  

  .page-content-wrapper {
    display: flex;
    .page-content {
        &.is-half {
            width: 50%;
            padding: 10px;

            .datatable {
                width: 100%;
            }
        }
    }
  }
}
</style>