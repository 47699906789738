<template>
    <div>
        <div class="admin-navbar">
            <div class="navbar-inner">
                <div class="left">
                    <a href="/">
                        <img
                        src="@/assets/images/Longevity.omics-logo-o.png"
                        alt="QuantumLife Logo"
                        />
                    </a>
                    <div class="separator"></div>
                    <div class="title is-4">Administration Dashboard</div>
                </div>
                <div class="center">
                    <div class="centered-links">
                        <a role="button" id="users-info" class="centered-link is-active" @click="setActiveTab('users-info')">
                            <iconify-icon class="iconify" icon="clarity:administrator-line" />
                            <span>Users Info</span>
                        </a>
                        <a role="button" id="waitlist" class="centered-link" @click="setActiveTab('waitlist')">
                            <iconify-icon class="iconify" icon="fluent-mdl2:waitlist-confirm" />
                            <span>Waitlist</span>
                        </a>
                        <!-- <a role="button" id="contact" class="centered-link" @click="setActiveTab('contact')">
                            <iconify-icon class="iconify" icon="material-symbols:contact-phone-outline" />
                            <span>
                                Contact
                            </span>
                        </a> -->
                        <a role="button" id="projects" class="centered-link" @click="setActiveTab('projects')">
                            <iconify-icon class="iconify" icon="eos-icons:project-outlined" />
                            <span>
                                Projects
                            </span>
                        </a>
                        <a role="button" id="my-info" class="centered-link" @click="setActiveTab('my-info')">
                            <iconify-icon class="iconify" icon="fluent:person-info-24-regular" />
                            <span>
                                My Info
                            </span>
                        </a>
                    </div>
                </div>
                <div class="right">
                    <div class="toolbar">
                        <div 
                            class="user-profile-dropdown" 
                            @mouseover="toggleDropdown(true)" 
                            @mouseleave="toggleDropdown(false)"
                        >
                            <img src="@/assets/images/profile.png" alt="User Profile" class="user-icon" />
                            <div v-if="dropdownActive" class="dropdown-menu show">
                                <router-link to="/dashboard" class="dropdown-item">Dashboard</router-link>
                                <a href="#" class="dropdown-item" @click="signOut">Log Out</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="admin-areas">
            <div class="admin-area" v-if="active_tab === 'users-info'">
                <UserInfoArea />
            </div>
            <div class="admin-area" v-if="active_tab === 'waitlist'">
                <WaitlistInfoArea />
            </div>
            <!-- <div class="admin-area" v-if="active_tab === 'contact'">
                <ContactArea />
            </div> -->
            <div class="admin-area" v-if="active_tab === 'projects'">
                <ProjectInfoArea />
            </div>
            <div class="admin-area" v-if="active_tab === 'my-info'">
                <MyInfoArea />
            </div>
        </div>
    </div>
    
</template>

<script>
import { auth, db } from '@/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import UserInfoArea from './UserInfoArea.vue';
import MyInfoArea from './MyInfoArea.vue';
import WaitlistInfoArea from './WaitlistInfoArea.vue';
import ProjectInfoArea from './ProjectInfoArea.vue';
import { USER_STATUS_QL_ADMIN, USER_STATUS_LO_ADMIN } from '../dashboard/dashboardConstants';

export default {
    name: "AdminNav",
    data() {
        return {
            user: null,
            active_tab: 'users-info',
            dropdownActive: false,
            admin_profile: null
        };
    },
    components: {
        UserInfoArea,
        MyInfoArea,
        WaitlistInfoArea,
        ProjectInfoArea
    },
    methods: {
        setActiveTab(tab) {
            this.active_tab = tab;
            const links = document.getElementsByClassName('centered-link');
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove('is-active');
            }
            const activeLink = document.getElementById(tab);
            activeLink.classList.add('is-active');
        },

        toggleDropdown(status) {
            this.dropdownActive = status;
        },

        signOut() {
            signOut(auth).then(() => {
                alert("You have successfully logged out");
                this.$router.push("/");
            }).catch((error) => {
                console.error("Sign out error:", error);
            });
        }
    },
    mounted() {
        this.setActiveTab(this.active_tab);

        onAuthStateChanged(auth, (user) => {
            if (!user) {
                this.$router.push("/login");
            }
            this.user = user;
            const docRef = doc(db, "users", user.uid);
            getDoc(docRef).then((doc) => {
                if (doc.exists()) {
                    this.admin_profile = doc.data();
                    if (this.admin_profile.status !== USER_STATUS_QL_ADMIN && this.admin_profile.status !== USER_STATUS_LO_ADMIN) {
                        alert("You are not authorized to access this page");
                        this.$router.push("/dashboard");
                    }
                } else {
                    alert("Failed to get user profile");
                    console.error("No such document for user profile", doc);
                }
            }).catch((error) => {
                console.error("Error getting document:", error);
            });
        });
    }
};
</script>

<style scoped>
.user-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    /* top: 100%; */
    right: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000; /* Ensure the dropdown appears on top */
    display: block;
}

.dropdown-item {
    padding: 8px 16px;
    display: block;
    color: #333;
    text-decoration: none;
}

.dropdown-item:hover {
    background-color: #f7f7f7;
}
</style>
